import React, { useState } from "react";
// database
import * as DATABASE from 'db';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import Modal from "components/Modal/Modal.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-kit-pro-react/views/teamSections/latestOffersStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTeamMembers() {
  const classes = useStyles();

  const cleanList = () => {
    let instructors = DATABASE.TEAM_MEMBERS.sort((a,b) => (a.name>b.name)*2-1).filter((member) => member.isActive).filter((member) => member.title === "Instructor");
    let owners = DATABASE.TEAM_MEMBERS.filter((member) => member.isActive).filter((member) => member.title === "Owner / Founder");
    let managers = DATABASE.TEAM_MEMBERS.filter((member) => member.isActive).filter((member) => member.title === "Manager");
    let teamMembers = owners.concat(managers);
    teamMembers = teamMembers.concat(instructors);
    return teamMembers;
  }

  const [newList, setNewList] = useState(cleanList());
  const [titleSelect, setTitleSelect] = useState("0");
  const [primarySelect, setPrimarySelect] = useState("0");
  const [secondarySelect, setSecondarySelect] = useState("0");
  const [filterData, setFilterData] = useState({
      titleType: ["title"],
      title: "0",
      primaryType: ["primary"],
      primary: "0",
      secondaryType: ["secondary"],
      secondary: "0",
    });

  const filterList = (type,searchValue) => {
    filterData[type] = searchValue;
    if(filterData.title === "0"){ filterData.title = "" };
    if(filterData.primary === "0"){ filterData.primary = "" };
    if(filterData.secondary === "0"){ filterData.secondary = "" };
    let tempList = cleanList().filter(obj => Object.keys(obj).some(key => {
        if(filterData.titleType.includes(key)){ return JSON.stringify(obj[key]).toLowerCase().includes(filterData.title.toLowerCase()) } else { return null };
    }));
    tempList = tempList.filter(obj => Object.keys(obj).some(key => {
      if(filterData.primaryType.includes(key)){ return JSON.stringify(obj[key]).toLowerCase().includes(filterData.primary.toLowerCase()) } else { return null };
    }));
    tempList = tempList.filter(obj => Object.keys(obj).some(key => {
      if(filterData.secondaryType.includes(key)){ return JSON.stringify(obj[key]).toLowerCase().includes(filterData.secondary.toLowerCase()) } else { return null };
    }));
    setNewList(tempList);
    if(filterData.title === ""){ filterData.title = "0" };
    if(filterData.primary === ""){ filterData.primary = "0" };
    if(filterData.secondary === ""){ filterData.secondary = "0" };
    setTitleSelect(filterData.title);
    setPrimarySelect(filterData.primary);
    setSecondarySelect(filterData.secondary);
    setFilterData(filterData);
  }

  return (
    <div className={classes.section}>
      {/* <div className={classes.teamHeaderContainer}>
        <h1 className={classes.teamTitle}>Our Team</h1>
        <h3 className={classes.teamTitle}>Read more about our amazing instructors and match them up to your child's personality</h3>
      </div> */}
      <div className={classes.container}>
        <GridContainer>
          <GridItem lg={4} md={4} sm={12} xs={12}>
            <label>Select Instructor Type</label>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={titleSelect}
                onChange={event => filterList(["title"],event.target.value)}
                inputProps={{name: "titleSelect", id: "title-select"}}>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="0">
                      All
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="Owner / Founder">
                      Owner / Founder
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="Manager">
                      Manager
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="Instructor">
                      Instructor
                  </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem lg={4} md={4} sm={12} xs={12}>
          <label>Primary Personality Type</label>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={primarySelect}
                onChange={event => filterList(["primary"],event.target.value)}
                inputProps={{name: "primarySelect", id: "primary-select"}}>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="0">
                      All
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="1">
                      1
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="2">
                      2
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="3">
                      3
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="4">
                      4
                  </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem lg={4} md={4} sm={12} xs={12}>
          <label>Secondary Personality Type</label>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={secondarySelect}
                onChange={event => filterList(["secondary"],event.target.value)}
                inputProps={{name: "secondarySelect", id: "secondary-select"}}>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="0">
                      All
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="1">
                      1
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="2">
                      2
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="3">
                      3
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                    value="4">
                      4
                  </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.teamContainer}>
          {newList.map((item,i) =>
          item.admin ?
          <GridItem lg={3} md={4} sm={6} xs={12} key={`Instructor-${item.name}-${i}`}>
            <Card product plain>
              <CardHeader image plain color="info">
                <img src={item.img} alt="..." />
                <div
                  // className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${item.img})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h3 className={classes.cardTitle}>{item.name}</h3>
                <h4 className={classes.cardSubTitle}>{item.title}</h4>
                <p className={classes.cardDescriptionStarted}>{`${item.work.substring(0, 145)}...`}</p>
                <Modal {...item} />
              </CardBody>
            </Card>
          </GridItem>
          :
          <GridItem lg={3} md={4} sm={6} xs={12} key={`Instructor-${item.name}-${i}`}>
            <Card product plain>
              <CardHeader image plain color="info">
                <img src={item.img} alt={`Utah Swim Academy - ${item.title} - ${item.name}`} />
                <div
                  // className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${item.img})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h3 className={classes.cardTitle}>{item.name}</h3>
                <h4 className={classes.cardSubTitle}>{item.title}</h4>
                {/* <CardFooter plain>
                <div className={classes.priceContainer}>
                  <a href={`mailTo:${item.email}`} target="_blank" rel="noopener noreferrer" className={classes.emailLink}>
                    {item.email}
                  </a>
                </div>
              </CardFooter> */}
                <div className={classes.moreInfoBox}>
                  <p className={classes.cardDescription}><span className={classes.title}>Primary Personality Type:</span> {item.primary}</p>
                  <p className={classes.cardDescription}><span className={classes.title}>Secondary Personality Type:</span> {item.secondary}</p>
                  {/* <p className={classes.cardDescription}><span className={classes.title}>Started with USA:</span> {new Date(item.started).toLocaleString('en-us',{month:'short', year:'numeric'})}</p> */}
                  <p className={classes.cardDescription}><span className={classes.title}>Speaks:</span> {item.language}</p>
                  {/* <p className={classes.cardDescription}><span className={classes.title}>Works Well With:</span> {item.work}</p> */}
                </div>
                <Modal {...item} />
              </CardBody>
            </Card>
          </GridItem>
          )}
        </GridContainer>
      </div>
    </div>
  );
}
