import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

import personalityCardImage0 from "assets/img/personality/image0.png"
import personalityCardImage1 from "assets/img/personality/image1.png"
import personalityCardImage2 from "assets/img/personality/image2.png"
import personalityCardImage3 from "assets/img/personality/image3.png"
import personalityCardImage4 from "assets/img/personality/image4.png"

import pricingStyle from "assets/jss/material-kit-pro-react/views/teamSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.pricing}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <h2 className={classes.title}>Selecting The Perfect Instructor</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3}></GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={personalityCardImage0} alt={`Utah Swim Academy - Personality Type Intro`} />
                  <div
                    style={{ backgroundImage: `url(${personalityCardImage0})`, opacity: 1 }}
                  />
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}></GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <h4 className={classes.description}>Have you wondered how to choose an instructor and which instructor would be the best fit for the type of lesson you are wanting for your childIf you have, we have a solution! According to Carol Tuttle's book, The Child Whisperer, all people fit into one of 4 main personality types. Our instructors have used these personality types to identify their teaching personality type. All of our instructors will adapt to teach all personality types but they specialize most in teaching to a specific personality type, based off of their teaching style.</h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={personalityCardImage1} alt={`Utah Swim Academy - Personality Type 1`} />
                  <div
                    style={{ backgroundImage: `url(${personalityCardImage1})`, opacity: 1 }}
                  />
                </CardHeader>
                <CardBody className={classes.textCenter} plain>
                  <h4 className={classes.description}>Lessons with a type 1 instructor will be fun, energetic, playful, lively, and optimistic! If you want these results in your lessons, schedule lessons with a type 1 instructor!</h4>
              </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={personalityCardImage2} alt={`Utah Swim Academy - Personality Type 2`} />
                  <div
                    style={{ backgroundImage: `url(${personalityCardImage2})`, opacity: 1 }}
                  />
                </CardHeader>
                <CardBody className={classes.textCenter} plain>
                  <h4 className={classes.description}>Lessons with a type 2 instructor will be calm, thoughtful, comforting, gentle, and tender. If you want these results in your lessons, schedule with a type 2 instructor!</h4>
              </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={personalityCardImage3} alt={`Utah Swim Academy - Personality Type 3`} />
                  <div
                    style={{ backgroundImage: `url(${personalityCardImage3})`, opacity: 1 }}
                  />
                </CardHeader>
                <CardBody className={classes.textCenter} plain>
                  <h4 className={classes.description}>Lessons with a type 3 instructor will be determined, active, focused, persistent, and driven. If you want these results in your lessons, schedule with a type 3 instructor!</h4>
              </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={personalityCardImage4} alt={`Utah Swim Academy - Personality Type 4`} />
                  <div
                    style={{ backgroundImage: `url(${personalityCardImage4})`, opacity: 1 }}
                  />
                </CardHeader>
                <CardBody className={classes.textCenter} plain>
                  <h4 className={classes.description}>Lessons with a type 4 instructor will be logical, analytical, confident, efficient, and methodical. If you want these results in your lessons, schedule with a type 4 instructor!</h4>
              </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <h3 className={classes.subtitle}>These Types of Personalities are from the book<br />"Child Whisperer" by Carol Tuttle</h3><br />
              <Button color="info" round href="https://cw.liveyourtruth.com/" target="_blank" size="lg" style={{ marginBottom: 50 }}>
                    LEARN MORE FROM "CHILD WHISPERER"
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
