/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionPersonality from "views/PersonalityPage/Sections/SectionPersonality.js";
import SectionEmailSubscription from "views/ContactUsPage/Sections/SubscribeLine.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/views/teamStyle.js";

const useStyles = makeStyles(styles);

export default function PersonalityPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/headers/team.jpg")}
        small
        title="Personality Types"
        description="Let Us Teach Your Children Life Saving Skills Year Round!"
        backgroundPosition={"center bottom"} />
      <div className={classNames(classes.main)}>
        <SectionPersonality />
      </div>
      <SectionEmailSubscription />
      <Footer theme="white" />
    </div>
  );
}
