import {
  section,
  container,
  containerFluid,
  cardTitle,
  coloredShadow,
  grayColor,
  dangerColor,
  mlAuto,
  infoColor
} from "assets/jss/material-kit-pro-react.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const styles = {
  cardTitle: {
    ...cardTitle,
    color: `${infoColor[0]}!important`
  },
  cardSubTitle: {
    ...cardTitle,
    color: `${infoColor[1]}!important`
  },
  container,
  ...imagesStyles,
  ...customSelectStyle,
  ...tooltipsStyle,
  section: {
    ...section,
    padding: "70px 0px"
  },
  coloredShadow,
  cardDescription: {
    color: grayColor[0],
    textAlign: "center",
    marginBottom: 4
  },
  moreInfoBox: {
    margin: '0 0 0px 0',
    height: 120
  },
  cardDescriptionLeft: {
    color: grayColor[0],
    textAlign: "left"
  },
  cardDescriptionStarted: {
    color: grayColor[0],
    textAlign: "center",
    marginBottom: 10,
    height: 110
  },
  mlAuto,
  priceContainer: {
    width: "100%",
    textAlign: "center"
  },
  teamHeaderContainer: {
    ...containerFluid,
    textAlign: "center",
    backgroundColor: infoColor[1],
    padding: '100px 0',
    margin: '50px 0'
  },
  teamTitle: {
    color: 'white',
    fontFamily: '"Sugar Snow", "Gratise", "Arial", sans-serif',
    fontWeight: "400"
  },
  teamContainer: {
    minHeight: 800
  },
  price: {
    fontSize: "18px",
    color: grayColor[22]
  },
  priceOld: {
    fontSize: "16px",
    textDecoration: "line-through"
  },
  priceNew: {
    color: dangerColor[0]
  },
  stats: {
    color: grayColor[0]
  },
  textCenter: {
    textAlign: "center"
  },
  emailLink: {
    fontSize: 12,
    color: infoColor[0],
    "&:hover": {
      color: infoColor[0],
      opacity: "0.5"
    },
    "&:focus": {
      color: infoColor[0]
    }
  },
  title: {
    fontWeight: 600,
    color: infoColor[1]
  }
};

export default styles;
