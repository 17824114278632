// function checkSeason(onSeason = false) {
//     let todayDate = new Date();
//     let startOffSeasonDate = new Date(`15 August ${new Date().getFullYear() + (todayDate.getMonth() < 2 ? -1 : 0)}`);
//     let endOffSeasonDate = new Date(`15 February ${new Date().getFullYear() + (todayDate.getMonth() < 2 ? 0 : 1)}`);
//     let startOnSeasonDate = new Date(`15 February ${new Date().getFullYear()}`);
//     let endOnSeasonDate = new Date(`15 August ${new Date().getFullYear()}`);

//     if(onSeason){
//         return todayDate > startOnSeasonDate && todayDate < endOnSeasonDate;
//     }

//     return todayDate > startOffSeasonDate && todayDate < endOffSeasonDate
// }

export const PRICES_LIST = [
    {
        title: "Fall/Winter Single Lesson",
        isActive: false,
        cost: "25",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy A Lesson",
            color: "info"
        },
        list: [
            {
                bold: "1",
                title: "Private 20 minute Lesson"
            },
            {
                bold: "Purchase",
                title: "Aug 15th - Feb 15th"
            },
            {
                bold: "Expires",
                title: "Within 3 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Fall/Winter 10 Pack Lessons",
        isActive: false,
        cost: "210",
        middle: true,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "white"
        },
        list: [
            {
                bold: "10",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Purchase",
                title: "Aug 15th - Feb 15th"
            },
            {
                bold: "Expires",
                title: "Within 3 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Fall/Winter 20 Pack Lessons",
        isActive: false,
        cost: "380",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "20",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Purchase",
                title: "Aug 15th - Feb 15th"
            },
            {
                bold: "Expires",
                title: "Within 3 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Single Lesson",
        isActive: true,
        cost: "25",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "1",
                title: "Private 20 minute Lesson"
            },
            {
                bold: "Expires",
                title: "Within 6 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Spring/Summer 5 Pack Lessons",
        isActive: false,
        cost: "115",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "5",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Purchase",
                title: "Feb 16th - Aug 14th"
            },
            {
                bold: "Expires",
                title: "Within 3 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "10 Pack Lessons",
        isActive: true,
        cost: "210",
        middle: true,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "10",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Expires",
                title: "Within 6 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "20 Pack Lessons",
        isActive: true,
        cost: "380",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "20",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Expires",
                title: "Within 6 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Cancellation Insurance",
        isActive: true,
        cost: "9.99/Month",
        middle: true,
        showButton: false,
        cardSize: 12,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Lesson cancellation insurance can be purchased for $9.99 per month."
            },
            {
                bold: "Allows Cancellation: ",
                title: "This allows you to cancel up to 5 minutes before your lesson time!"
            },
            {
                bold: "To Sign Up: ",
                title: "Send us your name and email (View our Contact Us Page). We will text you payment information and confirmation number."
            },
            {
                bold: "To Cancel a Lesson: ",
                title: "Call, Text, or DM with your name and confirmation number. We will take care of it for you!"
            }
        ]
    },
    {
        title: "Lesson Extensions for 1 Lesson",
        isActive: true,
        cost: "5",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 12,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "1",
                title: "Lesson Extension for 1 month"
            },
            {
                bold: "$2",
                title: "For each lesson afterward"
            }
        ]
    },
    {
        title: "Lesson Extensions for 10 Lessons",
        isActive: false,
        cost: "15",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "10",
                title: "Lessons Extension for 1 month"
            }
        ]
    },
    {
        title: "Lesson Extensions for 20 Lessons",
        isActive: false,
        cost: "30",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "20",
                title: "Lessons Extension for 1 month"
            }
        ]
    },
    {
        title: "Lesson Extensions for 30 Lessons",
        isActive: false,
        cost: "45",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "30",
                title: "Lessons Extension for 1 month"
            }
        ]
    },
    {
        title: "Swim Team (Per Practice Plan) Fall/Winter/Spring",
        isActive: false,
        cost: "15",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Practice: ",
                title: "Pay $15 per practice"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Saturday mornings for one hour (8:00am - 9:00am)."
            },
            {
                bold: "For more information: ",
                title: "Email our swim team coach at chelsea@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Per Month Plan) Fall/Winter/Spring",
        isActive: false,
        cost: "40",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Pay $40 per month"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Saturday mornings for one hour (8:00am - 9:00am)."
            },
            {
                bold: "For more information: ",
                title: "Email our swim team coach at chelsea@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Family Plan) Fall/Winter/Spring ",
        isActive: false,
        cost: "100",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Have 3 or more kids on the team"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Saturday mornings for one hour (8:00am - 9:00am)."
            },
            {
                bold: "For more information: ",
                title: "Email our swim team coach at chelsea@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Per Month Plan)",
        isActive: true,
        cost: "80",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Pay $75 per month"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Twice a week in the mornings (7:00am - 8:00am) and meets every other Saturday."
            },
            {
                bold: "For more information: ",
                title: "Email our swim team coach at chelsea@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Family Plan)",
        isActive: true,
        cost: "200",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "$200 per month for families with 3 or more kids on the team"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Twice a week in the mornings (7:00am - 8:00am) and meets every other Saturday."
            },
            {
                bold: "For more information: ",
                title: "Email our swim team coach at chelsea@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Adult Swimming Group Lessons (Per Lesson Plan)",
        isActive: false,
        cost: "15",
        middle: false,
        bumpUp: false,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Lesson: ",
                title: "$10 per lesson while swiming with a group"
            },
            {
                bold: "Requirements: ",
                title: "Must be 18+ and already know how to swim."
            },
            {
                bold: "Expectations: ",
                title: "Improve your swimming technique and get in a workout."
            },
            {
                bold: "When: ",
                title: "Tuesday and Thursday evenings from 7:30-8:30"
            },
            {
                bold: "Sign Up: ",
                title: "Email our support team at support@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Adult Swimming Group Lessons (Per Month Plan)",
        isActive: false,
        cost: "70",
        middle: false,
        bumpUp: false,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "$10 per lesson while swiming with a group"
            },
            {
                bold: "Requirements: ",
                title: "Must be 18+ and already know how to swim."
            },
            {
                bold: "Expectations: ",
                title: "Improve your swimming technique and get in a workout."
            },
            {
                bold: "When: ",
                title: "Tuesday and Thursday evenings from 7:30-8:30"
            },
            {
                bold: "Sign Up: ",
                title: "Email our support team at support@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Adult Swimming Private Lessons",
        isActive: false,
        cost: "19-52",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 12,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Private Lessons: ",
                title: "20 - 40 minutes lessons"
            },
            {
                bold: "Prices: ",
                title: "Prices are the same as the regular lessons and purchased through the website."
            },
            {
                bold: "When: ",
                title: "Flexible schedule by appointment only "
            },
            {
                bold: "Requirements: ",
                title: "For any skill level"
            },
            {
                bold: "Sign Up: ",
                title: "Email our support team at support@utahswimacademy.com"
            },
        ]
    },
]