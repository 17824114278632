/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import ExitToApp from "@material-ui/icons/ExitToApp";
import Category from "@material-ui/icons/Category";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import BeachAccess from "@material-ui/icons/BeachAccess";
import Call from "@material-ui/icons/Call";
import LocationOn from "@material-ui/icons/LocationOn";
import AttachMoney from "@material-ui/icons/AttachMoney";
import LiveHelp from "@material-ui/icons/LiveHelp";
import Pool from "@material-ui/icons/Pool";
import AccessTime from "@material-ui/icons/AccessTime";
import School from "@material-ui/icons/School";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Services"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Pool}
          dropdownList={[
            <Link to="/first" className={classes.dropdownLink}>
              <AccessTime className={classes.dropdownIcons} /> Getting Started
            </Link>,
            <Link to="/approach" className={classes.dropdownLink}>
              <Category className={classes.dropdownIcons} /> Our Approach
            </Link>,
            <Link to="/services" className={classes.dropdownLink}>
              <Pool className={classes.dropdownIcons} /> Our Services
            </Link>,
            <Link to="/scheduling" className={classes.dropdownLink}>
              <Assignment className={classes.dropdownIcons} /> Scheduling
            </Link>,
            <Link to="/online-courses" className={classes.dropdownLink}>
              <School className={classes.dropdownIcons} /> Online Courses
            </Link>
          //   <a
          //   href="https://utah-swim-academy.teachable.com/"
          //   target="_blank"
          //   className={classes.dropdownLink}>
          //     <Icon className={classes.dropdownIcons}>school</Icon>
          //     Online Courses
          // </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Prices"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={AttachMoney}
          dropdownList={[
            <Link to="/prices" className={classes.dropdownLink}>
              <AttachMoney className={classes.dropdownIcons} />Our Prices
            </Link>,
            // <Link to="/specials" className={classes.dropdownLink}>
            //   <BeachAccess className={classes.dropdownIcons} /> Specials
            // </Link>
          //   <a
          //   href="https://utah-swim-academy.teachable.com/"
          //   target="_blank"
          //   className={classes.dropdownLink}>
          //     <Icon className={classes.dropdownIcons}>school</Icon>
          //     Online Courses
          // </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Our Team"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={People}
          dropdownList={[
            <Link to="/team" className={classes.dropdownLink}>
              <People className={classes.dropdownIcons} /> Our Team
            </Link>,
            <Link to="/personality" className={classes.dropdownLink}>
              <Category className={classes.dropdownIcons} /> Personality Types
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Contact Us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Call}
          dropdownList={[
            <Link to="/contact" className={classes.dropdownLink}>
              <LocationOn className={classes.dropdownIcons} /> Contact Information
            </Link>,
             <a
              href="https://swim-academy.herokuapp.com/provo-pool/scheduler"
              target="_blank"
              className={classes.dropdownLink}>
                <Icon className={classes.dropdownIcons}>content_paste</Icon>
                Schedule A Lesson
            </a>,
            <Link to="/faq" className={classes.dropdownLink}>
              <LiveHelp className={classes.dropdownIcons} /> FAQs
            </Link>
          ]}
        />
      </ListItem>
      {window.innerWidth > 1200 &&
      <ListItem className={classes.listItem} style={{ marginRight: window.innerWidth > 900 ? 20 : 0 }}>
        <Button
          href="https://swim-academy.herokuapp.com/provo-pool/scheduler"
          color={"info"}
          target="_blank"
          className={classes.navButton}
          round
        >
          <Icon className={classes.icons}>content_paste</Icon> Schedule Lessons
        </Button>
      </ListItem>
      }
      <ListItem className={classes.listItem}>
        <Button
          href="https://swim-academy.herokuapp.com/users/sign_in"
          color={"info"}
          target="_blank"
          className={classes.navButton}
          round
        >
          <ExitToApp className={classes.icons} /> login
        </Button>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
