import {
  container,
  title,
  cardTitle,
  description,
  mlAuto,
  mrAuto,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  infoColor
} from "assets/jss/material-kit-pro-react.js";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import popoverStyles from "assets/jss/material-kit-pro-react/popoverStyles.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const javascriptStyles = theme => ({
  container,
  cardTitle,
  mlAuto,
  mrAuto,
  ...tooltipsStyle,
  ...popoverStyles,
  ...modalStyle(theme),
  ...customCheckboxRadioSwitch,
  section: {
    padding: "0 0 0 0"
  },
  name: {
    ...title,
    marginTop: 0,
    marginBottom: 0,
    textDecoration: "none",
    color: infoColor[0]
  },
  title: {
    ...title,
    marginTop: 0,
    marginBottom: 10,
    textDecoration: "none"
  },
  description: {
    ...description,
    marginTop: 10,
    marginBottom: 10,
    textDecoration: "none",
    color: infoColor[1],
    fontWeight: 600
  },
  descriptionSub: {
    color: grayColor[0]
  },
  emailLink: {
    fontSize: 16,
    color: infoColor[0],
    "&:hover": {
      color: infoColor[0],
      opacity: "0.5"
    },
    "&:focus": {
      color: infoColor[0]
    }
  },
  icon: {
    width: "24px",
    height: "24px",
    color: grayColor[13]
  },
  label: {
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0"
  },
  textCenter: {
    textAlign: "center"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  socialLineButton: {
    "&, &:hover": { color: whiteColor },
    marginLeft: "5px",
    marginRight: "5px"
  },
  cardLoginHeader: {
    marginTop: "-40px",
    padding: "20px 0",
    width: "100%",
    marginBottom: "15px"
  },
  cardLoginBody: {
    paddingTop: "0",
    paddingBottom: "0"
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  infoArea: {
    padding: "0px 0px 0px 0px!important",
    minHeight: 70
  },
  space50: {
    height: "50px",
    display: "block"
  },
  cardHeaderImage: {
    float: "left",
    padding: "0",
    zIndex: "1",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "0px",
    borderRadius: "6px",
    "& img": {
      width: "100%",
      borderRadius: "6px",
      pointerEvents: "none",
      boxShadow:
        "0 5px 15px -8px rgba(" +
        hexToRgb(blackColor) +
        ", 0.24), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    },
    "& a": {
      display: "block"
    }
  },
  // root: {
  //   '& .MuiPaper-root': {
  //     width: '100%',
  //     maxWidth: '400px'
  //   },
  // },
});

export default javascriptStyles;
